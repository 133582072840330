 @media (max-width: 997px) {
  .userpage {
    display: none;
  }
}

.btn-edit-profile {
  color: #fff;
  background-color: #00000099;
  border-color: #00000099;
}

.btn-primary {
  color: #fff;
  background-color: #00000099 !important;
  border-color: #00000099 !important;
}
a {
  color: #00000099;
  text-decoration: none;
  background-color: transparent;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 768px) {
  .registrybox {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
}
@media (max-width: 767px) {
  .registrybox {
    margin-top: 1rem !important;
    margin-bottom: 8rem !important;
  }
}