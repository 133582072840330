@media (min-width: 600px) {
    .topDivBar {
        display: block;
    }
}
@media (max-width: 599px) {
    .topDivBar {
        display: none;
    }
}
