@media (min-width: 768px) {
    .topStyle {
        width: 100% !important;
        height: 250px !important;
        background-image: url("https://portal.conectarhub.com.br/Banner_1920x400px.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #706f6f;
    }
}

@media (max-width: 767px) {
    .topStyle {
        width: 100% !important;
        height: 400px !important;
        background-image: url("https://portal.conectarhub.com.br/Banner_800x400px.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #706f6f;
    }
}

@media (max-width: 540px) {
    .topStyle {
        width: 100% !important;
        height: 150px !important;
        background-image: url("https://portal.conectarhub.com.br/Banner_360x150px.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #706f6f;
    }
}

@media (min-width: 600px) {
    .topDivBarMobile {
        display: none;
        text-align: "right";
    }
}
@media (max-width: 599px) {
    .topDivBarMobile {
        display: block;
        text-align: "right";
    }
}