 .bronze {
   background-color: #B0C4DE;
 }
 

 .silver {
   color: #fff;
   background-color: #C0C0C0;
  }
   
 .gold {
   color: #fff;
   background-color: #c2a60a;
  } 
  
  @media (min-width: 768px) {
    .registrybox {
      margin-top: 3rem !important;
    }
  }
  @media (max-width: 767px) {
    .registrybox {
      margin-top: 1rem !important;
    }
  }
   
 .content .plan {
     position:relative;
    
     margin-top: 1rem !important;
     background:#0000001a;
     padding-top:40px;
     padding-bottom:40px;
     border-radius:7px;
     color:#00000099;
     box-shadow:5px 5px 0 rgba(0,0,0,0.05);
     -webkit-transition:all .3s ease-out;
     transition:all .3s ease-out;
 }
 
 .content .plan.featured {
     top:0;
     padding-top:50px;
     padding-bottom:50px;
 }
 
 .content .plan:hover,
 .content .plan.featured {
    background: #ef8e00;
    color:#fff;
 }
 
 .content .plan h2 {
     font-size:28px;
     font-weight:700;
     margin-bottom:0;
     text-transform:uppercase;
 }
 
 .content .plan.featured h2 {
     margin-bottom:10px;
 }
 
 .content .plan p {
     margin-bottom:5px;
     font-weight:400;
 }
 
 .content .plan .icon {
     display:inline-block;
     margin-bottom:30px;
     width:120px;
     height:120px;
     border-radius:50%;
     font-size:48px;
     line-height:120px;
     -webkit-transition:all .3s ease-out;
     transition:all .3s ease-out;
 }
 
 .content .plan.featured .icon,
 .content .plan:hover .icon{
     color:#fff;
     background:#fff;
 }
 
 .content .plan .btn-pricing {
     margin-top:20px;
     margin-bottom:0px;
     font-size:16px;
     padding:10px 20px;
     color:#fff;
     background: #00000099;
 }
 
 .content .plan.featured .btn-pricing,
 .content .plan:hover .btn-pricing {
     background: #fff;
     color:#5CC9DF;
 }
 
 .content .plan .price {
     font-size:28px;
     font-weight:100;
     line-height:48px;
     margin-bottom:30px;
 }
 
 .content .plan.featured .price {
     margin-bottom:40px;
 }